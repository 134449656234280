<template>
    <!-- eslint-disable  -->    
      <b-card>   
        <b-row v-for="(item, index) in records" :key="index">
          
            <b-col  lg="12">
              <label class="project-graph">{{item.projectCode + '-' + item.projectName}}</label>
              <b-button class="ml-2 mb-1 mb-md-0" variant="primary" @click="exportExcel(item.projectId)">
                <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon" /> Exportar </span>
              </b-button>
            </b-col>
            <b-col lg="4">
              <div id="chart">
                <apexchart type="bar" height="380" :options="chartOptions" :series="item.pcrSeries"></apexchart>
              </div>
            </b-col>
            <b-col lg="4">
              <div id="chart">
                <apexchart type="bar" height="380" :options="chartOptionsTai" :series="item.taiSeries"></apexchart>
              </div>
            </b-col>
            <b-col lg="4">
              <div id="chart">
                <apexchart type="bar" height="380" :options="chartOptionsTal" :series="item.talSeries"></apexchart>
              </div>
            </b-col>
         
        </b-row>
       
      
      </b-card>
  
  </template>
  
  <script>
  /* eslint-disable */

  import RestrictionsService from '@/services/RestrictionsService'
  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
  import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
  import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
  import moment from 'moment'
  import Vue from 'vue'
  import Ripple from 'vue-ripple-directive'
  import VueApexCharts from 'vue-apexcharts'
  Vue.use(VueApexCharts)
  Vue.use(BootstrapVue)
  Vue.use(BootstrapVueIcons)
  const APIURL = process.env.APIURLFILE
  export default {
    directives: {
      'b-tooltip': VBTooltip,
      Ripple
    },
    data() {
      return {
        showLoading: false,
        show: true,       
        apiurl: APIURL,
        records : [],       
        userData: JSON.parse(localStorage.getItem('userData')),

        chartOptions: {
          colors: ['#001955'],
          chart: {
            height: 380,
            type: 'bar'             
          },           
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,             
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },         
     
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },            
          
          },
          title: {
            text: 'PCR',
            floating: false,
            offsetY: 0,
            align: 'left',
            style: {
              color: '#444'
            }
          }
        },
        chartOptionsTai: {
          colors: ['#001955'],
          chart: {
            height: 380,
            type: 'bar'             
          },           
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,             
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },         
     
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },            
          
          },
          title: {
            text: 'Promedio - Tiempo de Anticipacion en la Identificacion',
            floating: false,
            offsetY: 0,
            align: 'left',
            style: {
              color: '#444'
            }
          }
        },
        chartOptionsTal: {
          colors: ['#001955'],
          chart: {
            height: 380,
            type: 'bar'             
          },           
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,             
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },         
     
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },            
          
          },
          title: {
            text: 'Promedio - Tiempo de Anticipacion en la Liberacion',
            floating: false,
            offsetY: 0,
            align: 'left',
            style: {
              color: '#444'
            }
          }
        },
      
      }
    },
    components: {   
      AppCollapse,
      AppCollapseItem,
      'apexchart':VueApexCharts
    },
   
    mounted() {
       this.getValues()
       
    },
    watch: {
    
    },  
    methods: {
      async getValues(){
        const resp = await RestrictionsService.getValuesKpi(this.$store)
        console.log('resp', resp)     
        if(resp.status){
          const respData = resp.data
          console.log("USERDATA", this.userData)
          //filtrar records si es que el projectId de cada objeto es igual a los projectsId que le pasaré
          this.records = respData.filter(item => this.userData.projects.some(idObj => idObj.id == item.projectId))
          console.log("records", this.records)
        }
        this.showLoading = false
      },    
      async exportExcel(id) {
        console.log("ID", id)
        this.showLoading = true

        var fileLink = document.createElement('a')
        console.log(534)
        fileLink.href = this.apiurl + '/restriction/download/kpi-report/' + id
        console.log("FILELINK", fileLink.href);
        fileLink.setAttribute('target', '_blank')
        document.body.appendChild(fileLink)
        fileLink.click()

        this.showLoading = false
      },

    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .project-graph{
    margin-top: 25px;
    margin-bottom: 25px;
    font-weight: bold;
    font-size: larger;
    color: black;
  }
 
  </style>
  